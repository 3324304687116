import React, { useEffect, useState, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Container,
  Breadcrumbs,
  Link,
  Snackbar,
  Typography,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import Title from "../../../dashboard/Title";
import { checkCompanyAfterFetch } from "../../companyUtils";
import {
  updateCompanyDataServices,
  createCompanyDataServices,
} from "../../../../graphql/mutations";
import {
  ValidatorForm,
  SelectValidator,
} from "react-material-ui-form-validator";
import {
  createNewVersion,
  runKeyDeletion,
} from "../../../../utils/versionUtilFuncs";
import { getSalesCenterLeadSources } from "../../../../integrations/salesCenter/salesCenterService";

export function SalesCenterIntegration(props) {
  const { companyId } = useParams();

  const [salesCenterConfig, setSalesCenterConfig] = useState({});
  const [dataServices, setDataServices] = useState({});
  const [company, setCompany] = useState({});
  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Settings saved.");
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [error, setError] = useState("");

  const loadOptions = useCallback(
    async (salesCenterConfig) => {
      try {
        setLeadSourceOptions([
          { id: salesCenterConfig?.leadSource, name: "Loading Options" },
        ]);
        const options = await getSalesCenterLeadSources(salesCenterConfig);
        options.sort((a, b) =>
          a.name == b.name ? 0 : a.name > b.name ? 1 : -1,
        );
        const newOptions = [{ id: "", name: "", description: "" }, ...options];
        setLeadSourceOptions(newOptions);
      } catch (ex) {
        setError(
          "Unable to load the Lead Source Options from Sales Center. Please check the values in the Data Services page.",
        );
      }
    },
    [getSalesCenterLeadSources],
  );

  const fetchCompany = useCallback(async () => {
    try {
      const fetch = await checkCompanyAfterFetch(companyId);
      const loadedDataServices = fetch.data.getCompany.dataServices || {
        stripeTestSecret: "",
        stripeSecret: "",
        realGreenAPIKey: "",
        zillowAPIKey: "",
        airTableTableName: "",
        airTableBase: "",
        googleSheetsClientEmail: "",
        googleSheetsPrivateKey: "",
        servmanApiAuth: "",
        servmanApiKey: "",
        servmanApiUrl: "",
      };
      const loadedSalesCenterConfig = loadedDataServices?.salesCenterConfig
        ? JSON.parse(loadedDataServices?.salesCenterConfig)
        : { apiKey: "", tenantId: "", clientId: "", apiBaseUrl: "" };
      setCompany(fetch.data.getCompany);
      setDataServices(loadedDataServices);
      setSalesCenterConfig(loadedSalesCenterConfig);

      if (
        loadedSalesCenterConfig?.apiKey &&
        loadedSalesCenterConfig?.tenantId &&
        loadedSalesCenterConfig?.clientId &&
        loadedSalesCenterConfig?.apiBaseUrl
      ) {
        loadOptions(loadedSalesCenterConfig);
      } else {
        setError(
          "Unable to load the Lead Source Options from Sales Center. Please check the values in the Data Services page.",
        );
      }
    } catch (ex) {}
  }, [companyId, loadOptions]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  const saveSalesCenterCompanyData = useCallback(async () => {
    const newDataService = { ...dataServices };
    newDataService.salesCenterConfig = JSON.stringify(salesCenterConfig);
    newDataService.companyDataServicesCompanyId = company.id;

    try {
      if (newDataService.id) {
        runKeyDeletion(newDataService, "dataServices");
        await API.graphql(
          graphqlOperation(updateCompanyDataServices, {
            input: newDataService,
          }),
        );
      } else {
        delete newDataService.__typename;
        const saveResult = await API.graphql(
          graphqlOperation(createCompanyDataServices, {
            input: newDataService,
          }),
        );
        const bid = saveResult.data.createCompanyDataServices.id;
        setDataServices(saveResult.data.createCompanyDataServices);
        const cpayload = { id: company.id, companyDataServicesId: bid };
        await createNewVersion(companyId, "company", cpayload);
      }
      setSnackMessage("Settings saved.");
      openSnack(true);
    } catch (ex) {
      setSnackMessage("Settings failed to save. Please try again.");
      openSnack(true);
    }
  }, [dataServices, company, salesCenterConfig, openSnack]);

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>

        <Typography color="textPrimary">Sales Center</Typography>
      </Breadcrumbs>
      <div style={{ width: "100%", padding: "32px 0px" }}>
        {error ? (
          <div>{error}</div>
        ) : (
          <ValidatorForm onSubmit={() => {}}>
            <div style={{ paddingBottom: "15px" }}>
              <Title>Sales Center Settings</Title>

              <SelectValidator
                id="leadSourceSelector"
                label="Lead Source"
                value={salesCenterConfig?.leadSource || ""}
                style={{ margin: 8, width: 400 }}
                placeholder="Lead Source"
                margin="normal"
                onChange={(e) => {
                  setSalesCenterConfig({
                    ...salesCenterConfig,
                    leadSource: e.target.value,
                  });
                }}
              >
                {leadSourceOptions.map((i) => {
                  const key = "menu-item-" + i.id;
                  return (
                    <MenuItem
                      style={{ minHeight: "24px" }}
                      key={key}
                      value={i.name}
                    >
                      {i.name} {i.description ? `- ${i.description}` : ""}
                    </MenuItem>
                  );
                })}
              </SelectValidator>
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={saveSalesCenterCompanyData}
            >
              Save
            </Button>
          </ValidatorForm>
        )}
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={2000}
        onClose={() => {
          openSnack(false);
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      ></Snackbar>
    </Container>
  );
}
