import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  createStyles,
  Container,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import PriceTableListTable from "./price-table-list-table";
import { checkCompanyAfterFetch } from "../companyUtils";
import { Link as NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    newButton: {
      position: "relative",
      float: "right",
      marginRight: 20,
    },
  }),
);

export default function CompanyPriceTablePage(props) {
  const { companyId } = useParams();
  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);
  const [priceTables, setPriceTables] = useState([]);

  const fetchCompany = useCallback(async () => {
    try {
      const fetch = await checkCompanyAfterFetch(companyId);
      setPriceTables(fetch.data.getCompany.priceTables.items);
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error);
    }
  }, [companyId]);

  useEffect(() => {
    // fetch customer record here
    fetchCompany();
  }, [fetchCompany]);

  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>

        <Typography color="textPrimary">Price Tables</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Button
          className={classes.newButton}
          color="primary"
          variant="contained"
          component={NavLink}
          to={`/company/${companyId}/price-table/new`}
        >
          New +
        </Button>
        {loaded ? (
          <PriceTableListTable
            companyId={companyId}
            priceTables={priceTables}
            classes={classes}
          />
        ) : null}
      </div>
    </Container>
  );
}
