const botStatuses = [
  {
    name: "Not Started",
    value: "Not Started",
  },
  {
    name: "Building",
    value: "Building",
  },
  {
    name: "Quality Control",
    value: "Quality Control",
  },
  {
    name: "Pre-Launch",
    value: "Pre-Launch",
  },
  {
    name: "Live",
    value: "Live",
  },
];

export default botStatuses;
