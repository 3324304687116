import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, makeStyles, createStyles, Button } from "@material-ui/core";

import Title from "../../dashboard/Title";
import { API, graphqlOperation } from "aws-amplify";
import { BotInfoForm } from "./bot-info-form";
import { updateBot } from "../../../graphql/mutations";
import { getBotVersioned } from "../../../graphql/queries";

import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { objectionsTemplate } from "./templates/objections.template";
import { createNewVersion } from "../../../utils/versionUtilFuncs";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export function CompanyBotObjections(props) {
  const { companyId, botId } = useParams();
  const classes = useStyles();

  const [bot, setBot] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [objections, setObjections] = useState({});

  const fetchBot = async () => {
    try {
      const fetch = await API.graphql(
        graphqlOperation(getBotVersioned, { id: botId, version: "v0" }),
      );
      if (fetch.data.getBotVersioned.objections) {
        try {
          fetch.data.getBotVersioned.objections = JSON.parse(
            fetch.data.getBotVersioned.objections,
          );
        } catch (e) {
          console.log(e);
        }
      } else {
        fetch.data.getBotVersioned.objections = JSON.parse("[]");
      }
      setBot(fetch.data.getBotVersioned);
      setObjections(fetch.data.getBotVersioned.objections);
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    // fetch customer record here
    fetchBot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateBotCb = async () => {
    const payload = { ...bot, objections: JSON.stringify(objections, null, 2) };
    if (payload.objections.objections) {
      payload.objections = payload.objections.objections;
    }
    try {
      await createNewVersion(bot.id, "bot", payload);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return loaded ? (
    <Container className={classes.root}>
      <div className={classes.container}>
        <Title>{bot.botTitle} Learn More</Title>
      </div>
      <div className={classes.container}>
        <Button
          style={{ marginRight: 10, marginBottom: 20 }}
          onClick={updateBotCb}
          color="primary"
          variant="contained"
        >
          Save Objections
        </Button>
        <Editor value={objections} onChange={(e) => setObjections(e)} />
      </div>
    </Container>
  ) : null;
}
