import { API } from "aws-amplify";

export const getSalesCenterLeadSources = async (salesCenterConfig) => {
  try {
    const data = await API.post(
      "bridgeApi",
      `/dashboard/sales-center/lead-sources`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        body: salesCenterConfig,
      },
    );
    return data;
  } catch (e) {
    throw Error("Failed to retrieve Sales Center Lead Sources.");
  }
};
