import React, { useState, makeStyles, createStyles } from "react";
import Title from "../../pages/dashboard/Title";
import ConditionRow from "./condition-row";
import { Table, TableBody } from "@material-ui/core";
import PropTypes from "prop-types";

export default function Conditions(props) {
  const [conditions, setConditions] = useState(props.conditions || []);

  const { updatePriceTableConditions } = props;

  const updateConditionCb = (attr) => {
    const ncondtions = conditions.map((i) => {
      if (i.id !== attr.id) return i;
      return attr;
    });
    setConditions(ncondtions);
    updatePriceTableConditions(ncondtions);
  };

  const createConditionCb = (attr) => {
    const id = "conditionId" + Math.round(Math.random() * 1000000);
    attr.id = id;
    const newconditions = [...conditions, attr];
    setConditions(newconditions);
    updatePriceTableConditions(newconditions);
  };

  const deleteConditionCb = (id) => {
    const filtered = conditions.filter((i) => i.id !== id);
    setConditions(filtered);
    updatePriceTableConditions(filtered);
  };

  return (
    <div className="root">
      <Title>Conditions</Title>
      <div>
        New Condition
        <Table>
          <TableBody>
            <ConditionRow
              key={"condition_form"}
              condition={{}}
              type={props.type}
              editable={true}
              updateCondition={createConditionCb}
              rowType="form"
            />
          </TableBody>
        </Table>
      </div>
      <br />
      <br />
      <div className="container">
        Conditions List
        <Table>
          <TableBody>
            {conditions.map((i) => {
              const key = "condition_key-" + Math.round(Math.random() * 100000);
              return (
                <ConditionRow
                  key={key}
                  condition={i}
                  type={props.type}
                  editable={false}
                  updateCondition={updateConditionCb}
                  deleteCondition={deleteConditionCb}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

Conditions.propTypes = {
  updatePriceTableConditions: PropTypes.func,
  conditions: PropTypes.array,
};
