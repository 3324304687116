import { templatedCommercialCleaning } from "./probing_questions_commercial_cleaning";
import { templatedIrrigation } from "./probing_questions_irrigation";
import { templatedLawnCare } from "./probing_questions_lawn";
import { templatedPets } from "./probing_questions_pet_waste";
import { templatedPests } from "./probing_questions_pests";
import { templatedResidentialCleaning } from "./probing_questions_residential_cleaning";

const PROBING_QUESTIONS_TEMPLATES = {
  COMMERCIAL_CLEANING: templatedCommercialCleaning,
  IRRIGATION_SERVICES: templatedIrrigation,
  LAWN_CARE: templatedLawnCare,
  PET_WASTE: templatedPets,
  PEST_CONTROL: templatedPests,
  RESIDENTIAL_CLEANING: templatedResidentialCleaning,
};
export default PROBING_QUESTIONS_TEMPLATES;
