import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Snackbar,
} from "@material-ui/core";

import Title from "../dashboard/Title";
import { CompanyInfoForm } from "./company-info-form";
import { checkCompanyAfterFetch } from "./companyUtils";
import { createNewVersion } from "../../utils/versionUtilFuncs";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export function CompanyDetail(props) {
  const { companyId } = useParams();
  const classes = useStyles();

  const [company, setCompany] = useState({});
  const [loaded, setLoaded] = useState(false);

  const [snackOpened, openSnack] = useState(false);

  const fetchCompany = useCallback(async () => {
    try {
      const fetch = await checkCompanyAfterFetch(companyId);
      setCompany(fetch.data.getCompany);
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error?.errors ? error.errors[0].message : error);
    }
  }, [companyId]);

  useEffect(() => {
    // fetch customer record here
    fetchCompany();
  }, [fetchCompany]);

  const updateCompanyCb = async (company) => {
    try {
      await createNewVersion(companyId, "company", company);
      openSnack(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };
  return (
    <Container className={classes.root}>
      <div className={classes.container}>
        <Title>Customer Information</Title>
      </div>
      <div className={classes.container}>
        {loaded ? (
          <CompanyInfoForm
            company={company}
            submit={updateCompanyCb}
            action={"UPDATE"}
          />
        ) : null}
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={3000}
        onClose={() => {
          openSnack(false);
        }}
        message="Company Saved"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </Container>
  );
}
