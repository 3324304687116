export const createTitle = (companyName, path) => {
  let title;

  switch (true) {
    case /companies/.test(path):
      title = "All Companies";
      break;
    case /company\/[A-Za-z0-9-]+$/.test(path):
      title = `Company Details / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/branding$/.test(path):
      title = `Company Branding / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/bots$/.test(path):
      title = `Company Bots / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/bot\/[A-Za-z0-9-]+$/.test(path):
      title = `Bot Details / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/bot\/[A-Za-z0-9-]+\/learn-more$/.test(path):
      title = `Dashboard / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/bot\/[A-Za-z0-9-]+\/pre-modal$/.test(path):
      title = `Dashboard / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/bot\/[A-Za-z0-9-]+\/objections$/.test(path):
      title = `Dashboard / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/bot\/[A-Za-z0-9-]+\/service-questions$/.test(
      path,
    ):
      title = `Dashboard / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/programs$/.test(path):
      title = `Company Programs / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/program\/[A-Za-z0-9-]+$/.test(path):
      title = `Program Details / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/price-tables$/.test(path):
      title = `Company Price Tables / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/price-table\/[A-Za-z0-9-]+$/.test(path):
      title = `Price Table Details / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/data-services$/.test(path):
      title = `Company Data Services / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/real-green$/.test(path):
      title = `Company RealGreen Settings / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/lawnbot-dashboard$/.test(path):
      title = `ServiceBot Dashboard Settings / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/offers$/.test(path):
      title = `Company Offers / ${companyName}`;
      break;
    case /company\/[A-Za-z0-9-]+\/offer\/[A-Za-z0-9-]+$/.test(path):
      title = `Company Offers / ${companyName}`;
      break;
    default:
      title = "Dashboard";
  }

  return title;
};
