import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Breadcrumbs,
  Link,
  Typography,
  Snackbar,
} from "@material-ui/core";

import Title from "../../dashboard/Title";

import { TextField, MenuItem, Button } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { checkCompanyAfterFetch } from "../companyUtils";
import { createNewVersion } from "../../../utils/versionUtilFuncs";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export function GoogleSheetIntegration(props) {
  const { companyId } = useParams();
  const classes = useStyles();

  const [company, setCompany] = useState({});
  const [loaded, setLoaded] = useState(false);

  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Settings Saved");

  const fetchCompany = useCallback(async () => {
    try {
      const fetch = await checkCompanyAfterFetch(companyId);

      fetch.data.getCompany.crmCompanyId =
        typeof fetch.data.getCompany.crmCompanyId === "undefined"
          ? "-1"
          : fetch.data.getCompany.crmCompanyId + "";
      setCompany(fetch.data.getCompany);
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  }, [companyId]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  const saveCompanyData = async () => {
    const payload = { ...company };
    try {
      await createNewVersion(companyId, "company", payload);
      openSnack(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };

  const handleSave = async (e) => {
    // await saveCompanyData();
  };

  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>

        <Typography color="textPrimary">Google Sheet</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <ValidatorForm onSubmit={handleSave}>
          <Title>Google Sheet Pricing</Title>

          <Button color="primary" variant="contained" onClick={handleSave}>
            Save
          </Button>
        </ValidatorForm>
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={2000}
        onClose={() => {
          openSnack(false);
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      ></Snackbar>
    </Container>
  );
}
