import React, { Fragment } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";

import AssistantIcon from "@material-ui/icons/Assistant";
import PeopleIcon from "@material-ui/icons/People";

import LayersIcon from "@material-ui/icons/Layers";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import Android from "@material-ui/icons/Android";
import ImageIcon from "@material-ui/icons/Image";
import LocalAtm from "@material-ui/icons/LocalAtm";
import Dns from "@material-ui/icons/Dns";
import OfflineBolt from "@material-ui/icons/OfflineBolt";
import TimelineIcon from "@material-ui/icons/Timeline";

import ContactMailIcon from "@material-ui/icons/ContactMail";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

import { Link as NavLink } from "react-router-dom";
import { Divider } from "@material-ui/core";

export const mainListItems = (
  <div>
    <ListItem button>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <NavLink to="/">
        <ListItemText primary="Dashboard" />
      </NavLink>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <NavLink to="/companies">
        <ListItemText primary="Companies" />
      </NavLink>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssistantIcon />
      </ListItemIcon>
      <NavLink to="/bots">
        <ListItemText primary="Bots" />
      </NavLink>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <NavLink to="/templates">
        <ListItemText primary="Templates" />
      </NavLink>
    </ListItem>
  </div>
);

export const customerListItems = (company) => (
  <div>
    <ListItem button>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <NavLink to="/">
        <ListItemText primary="Dashboard" />
      </NavLink>
    </ListItem>
    <Divider />
    <ListItem button>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <NavLink to={`/company/${company.id}`}>
        <ListItemText primary="Business" />
      </NavLink>
    </ListItem>
    {company.id !== "new" && (
      <Fragment>
        <ListItem button>
          <ListItemIcon>
            <ImageIcon />
          </ListItemIcon>
          <NavLink to={`/company/${company.id}/branding`}>
            <ListItemText primary="Branding" />
          </NavLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <Android />
          </ListItemIcon>
          <NavLink to={`/company/${company.id}/bots`}>
            <ListItemText primary="Bots" />
          </NavLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <Dns />
          </ListItemIcon>
          <NavLink to={`/company/${company.id}/programs`}>
            <ListItemText primary="Programs" />
          </NavLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <LocalAtm />
          </ListItemIcon>
          <NavLink to={`/company/${company.id}/price-tables`}>
            <ListItemText primary="Price Tables" />
          </NavLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <OfflineBolt />
          </ListItemIcon>
          <NavLink to={`/company/${company.id}/data-services`}>
            <ListItemText primary="Data Services" />
          </NavLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <LocalOfferIcon />
          </ListItemIcon>
          <NavLink to={`/company/${company.id}/offers`}>
            <ListItemText primary="Offers" />
          </NavLink>
        </ListItem>
        {company.crmSolution !== "real-green" ? null : (
          <ListItem button>
            <ListItemIcon>
              <ContactMailIcon />
            </ListItemIcon>
            <NavLink to={`/company/${company.id}/real-green`}>
              <ListItemText primary="Real Green" />
            </NavLink>
          </ListItem>
        )}
        {company.crmLeadSolution !== "sales-center" ? null : (
          <ListItem button>
            <ListItemIcon>
              <ContactMailIcon />
            </ListItemIcon>
            <NavLink to={`/company/${company.id}/sales-center`}>
              <ListItemText primary="Sales Center" />
            </NavLink>
          </ListItem>
        )}
        {company.crmSolution !== "pest-pac" ? null : (
          <ListItem button>
            <ListItemIcon>
              <ContactMailIcon />
            </ListItemIcon>
            <NavLink to={`/company/${company.id}/pest-pac`}>
              <ListItemText primary="Pest Pac" />
            </NavLink>
          </ListItem>
        )}
        <ListItem button>
          <ListItemIcon>
            <TimelineIcon />
          </ListItemIcon>
          <NavLink to={`/company/${company.id}/lawnbot-dashboard`}>
            <ListItemText primary="ServiceBot Dashboard" />
          </NavLink>
        </ListItem>
      </Fragment>
    )}
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Knowledge Base</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Creating a Company" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Data Services" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Using Paths" />
    </ListItem>
  </div>
);
