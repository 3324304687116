import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";

//whatKindOfGrass
//Zoysia

export default function ConditionRow(props) {
  const conditionTemplate = {
    id: null,
    variable: "",
    type: props.type || "adjustment",
    operator: "isSet",
    compareValue: "True",
    adjustValue: 0,
    adjustUnit: "dollars",
    selectAction: "recommend",
  };

  const [editable, setEditable] = useState(props.editable || false);

  const initCondition = Object.assign(
    {},
    conditionTemplate,
    props.condition || {},
  );

  const [condition, setCondition] = useState(initCondition);

  const conditionType = [
    {
      value: "recommend",
      label: "Recommend",
    },
    {
      value: "adjustment",
      label: "Adjustment",
    },
  ];
  const adjustUnits = [
    {
      value: "dollars",
      label: "Dollars ($)",
    },
    {
      value: "percentage",
      label: "Percentage (%)",
    },
  ];
  const operators = [
    {
      value: "isSet",
      label: "Is Set",
    },
    {
      value: "isNotSet",
      label: "Is Not Set",
    },
    {
      value: "equals",
      label: "Equals",
    },
    {
      value: "doesNotEqual",
      label: "Does Not Equal",
    },
    {
      value: "gt",
      label: "Greater Than",
    },
    {
      value: "gte",
      label: "Greater Than Or Equal",
    },
    {
      value: "lt",
      label: "Less Than",
    },
    {
      value: "lte",
      label: "Less Than Or Equal",
    },
  ];

  const selectActions = [
    {
      value: "recommend",
      label: "Recommend",
    },
    {
      value: "dontRecommend",
      label: "Don`t Recommend",
    },
  ];

  const variableList = [
    {
      value: "irrigationSystemType",
      label: "Irrigation System Type",
      possibleValues: ["New", "Existing"],
    },
    {
      value: "soilType",
      label: "Soil Type",
      possibleValues: ["Sandy Soil", "Clay Soil", "Rocky Soil", "Rooty Soil"],
    },
    {
      value: "landscapeConditions",
      label: "Landscape Conditions",
      possibleValues: ["Normal", "Meticulous"],
    },
    {
      value: "homeType",
      label: "Home Type",
      possibleValues: ["New Home", "Established Home", "Under Construction"],
    },
    {
      value: "numberOfZones",
      label: "Number of Zones",
      possibleValues: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
    },
    {
      value: "getBackflowTest",
      label: "Get Backflow Test",
      possibleValues: ["Yes", "No"],
    },
    {
      value: "numberOfCheckups",
      label: "Number of Checkups",
      possibleValues: ["One", "Two"],
    },
    {
      value: "lawnCareParty",
      label: "Lawn Care Party",
      possibleValues: [
        "I do it myself",
        "I use another company",
        "I just moved in",
      ],
    },
    {
      value: "lawnHappiness",
      label: "Lawn Happines",
      possibleValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      value: "whyNewProvider",
      label: "Why New Provider",
      possibleValues: ["Customer Service", "Better Quality", "Better Pricing"],
    },
    {
      value: "lawnThickness",
      label: "Lawn Thickness",
      possibleValues: ["Thick", "Patchy", "Thin"],
    },
    {
      value: "lawnBiggestProblem",
      label: "Lawn Biggest Problem",
      possibleValues: [
        "Broadleaf weeds",
        "Stubborn Weeds",
        "Grubs",
        "Crabgrass",
        "Disease",
        "Shade",
      ],
    },
    {
      value: "lawnType",
      label: "Lawn Type",
      possibleValues: [
        "Bermuda",
        "Zoysia",
        "Tall fescue",
        "St augistine",
        "I don't know",
      ],
    },
  ];

  const [conditionValues, setConditionValues] = useState(
    variableList[0].possibleValues,
  );

  const dispatchUpdate = () => {
    const { updateCondition, rowType = null } = props;
    updateCondition(condition);
    if (rowType === "form") {
      setCondition(Object.assign({}, conditionTemplate, props.condition));
    } else {
      setEditable(false);
    }
  };

  const dispatchDelete = () => {
    const { deleteCondition } = props;
    const confirm = window.confirm("Are You Sure?");
    if (confirm) {
      deleteCondition(condition.id);
    }
  };

  return editable ? (
    <TableRow>
      <TableCell>
        <TextField
          id="variable"
          label="Variable Name"
          value={condition.variable}
          style={{ margin: 8, width: 100 }}
          placeholder="cameCaseVariable"
          onChange={(e) => {
            setCondition({ ...condition, variable: e.target.value });
          }}
        />
      </TableCell>

      <TableCell>
        <Select
          id="conditionType"
          style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
          value={condition.type}
          onChange={(e) => {
            setCondition({ ...condition, type: e.target.value });
          }}
        >
          {conditionType.map((i) => {
            const key = "menu-item-" + Math.floor(Math.random() * 100000);
            return (
              <MenuItem key={key} value={i.value}>
                {i.label}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>

      <TableCell>
        <Select
          id="operator"
          label="Operation"
          style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
          value={condition.operator}
          onChange={(e) => {
            setCondition({ ...condition, operator: e.target.value });
          }}
        >
          {operators.map((i) => {
            const key = "menu-item-" + Math.floor(Math.random() * 100000);
            return (
              <MenuItem key={key} value={i.value}>
                {i.label}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      {condition.operator !== "isSet" && condition.operator !== "isNotSet" ? (
        <TableCell>
          <TextField
            id="compareValue"
            label="Compare Value"
            value={condition.compareValue}
            style={{ margin: 8, width: 100 }}
            placeholder="True"
            onChange={(e) => {
              setCondition({ ...condition, compareValue: e.target.value });
            }}
          />
        </TableCell>
      ) : null}

      {condition.type === "adjustment" ? (
        <TableCell>
          <TextField
            id="adjustValue"
            label="Adjustement Value"
            value={condition.adjustValue}
            style={{ margin: 8, width: 100 }}
            placeholder="0.0"
            onChange={(e) => {
              setCondition({ ...condition, adjustValue: e.target.value });
            }}
          />
        </TableCell>
      ) : null}

      {condition.type === "adjustment" ? (
        <TableCell>
          <Select
            id="adjustUnit"
            style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
            value={condition.adjustUnit}
            onChange={(e) => {
              setCondition({ ...condition, adjustUnit: e.target.value });
            }}
          >
            {adjustUnits.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.value}>
                  {i.label}
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>
      ) : null}

      {condition.type === "recommend" ? (
        <TableCell>
          <Select
            id="selectAction"
            style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
            value={condition.selectAction}
            onChange={(e) => {
              setCondition({ ...condition, selectAction: e.target.value });
            }}
          >
            {selectActions.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.value}>
                  {i.label}
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>
      ) : null}
      <TableCell>
        <Button
          color="primary"
          variant="contained"
          onClick={() => dispatchUpdate()}
        >
          Save
        </Button>
        {props.rowType !== "form" ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => dispatchDelete()}
          >
            Delete
          </Button>
        ) : null}
      </TableCell>
    </TableRow>
  ) : (
    <TableRow>
      <TableCell>{condition.variable}</TableCell>
      <TableCell>{condition.conditionType}</TableCell>
      <TableCell>{condition.operator}</TableCell>
      {condition.operator !== "isSet" && condition.operator !== "isNotSet" ? (
        <TableCell>{condition.compareValue}</TableCell>
      ) : null}
      {condition.type === "adjustment" ? (
        <TableCell>{conditionType.adjustValue}</TableCell>
      ) : null}
      {condition.type === "adjustment" ? (
        <TableCell>{conditionType.adjustUnit}</TableCell>
      ) : null}
      {condition.type === "recommend" ? (
        <TableCell>{condition.selectAction}</TableCell>
      ) : null}
      <TableCell>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setEditable(true)}
        >
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
}

ConditionRow.propTypes = {
  updateCondition: PropTypes.func,
  deleteCondition: PropTypes.func,
  editable: PropTypes.bool,
  type: PropTypes.string,
  rowType: PropTypes.string,
};
