export const templatedPests = [
  {
    id: "pest-questions",
    steps: [
      {
        id: "pest-control-entry",
        message: "What types of issues are you having?",
        trigger: "get-bugging-you",
      },
      {
        id: "perimeter-pest-control-entry",
        message: "What types of issues are you having?",
        trigger: "get-bugging-you",
      },
    ],
    probing_questions: [
      {
        id: "get-bugging-you",
        options: [
          {
            value: "Ants",
            label: "Ants",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/pest_ants.jpg",
          },
          {
            value: "Spiders",
            label: "Spiders",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/pest_spiders.jpg",
          },
          {
            value: "Roaches",
            label: "Roaches",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/pest_roaches.jpg",
          },
          {
            value: "Rodents",
            label: "Rodents",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/pest_rodents.jpeg",
          },
          {
            value: "Other",
            label: "Other",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/pest_other.jpeg",
          },
        ],
        setVariable: "pestBiggestProblem",
        optionType: "image-text-option",
        fillOptions: true,
        trigger: "next-service",
      },
    ],
  },
];
