import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Button, Container } from "@material-ui/core";

import { useParams } from "react-router-dom";
import Title from "../../../dashboard/Title";
import { JsonEditor as Editor } from "jsoneditor-react";
import { createNewVersion } from "../../../../utils/versionUtilFuncs";
import useProgramProbingQuestions from "../../../../hooks/programs/useProgramProbingQuestions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  newButton: {
    position: "relative",
    float: "right",
    marginRight: 20,
  },
}));

export default function ProgramProbingQuestionsPage() {
  const { programId } = useParams();
  const classes = useStyles();

  const { loaded, probingQuestions, setProbingQuestions, program } =
    useProgramProbingQuestions(programId);

  const updateProgamCb = async () => {
    const payload = {
      ...program,
      probingQuestionsJson: JSON.stringify(probingQuestions, null, 2),
    };
    try {
      await createNewVersion(program.id, "program", payload);
      alert("Program Probing Questions Saved");
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Container classes={classes.root}>
      <div className={classes.container}>
        <Title>Probing Questions</Title>
        {loaded ? (
          <div>
            <Button
              style={{ marginRight: 10, marginBottom: 20 }}
              onClick={updateProgamCb}
              color="primary"
              variant="contained"
            >
              Save Program Probing Questions
            </Button>
            <Editor
              value={probingQuestions}
              onChange={(e) => setProbingQuestions(e)}
            />
          </div>
        ) : null}
      </div>
    </Container>
  );
}
