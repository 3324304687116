import React, { useState, useEffect } from "react";
import {
  MenuItem,
  Button,
  InputAdornment,
  Checkbox,
  FormLabel,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Select,
  Card,
} from "@material-ui/core";
import { PropTypes } from "prop-types";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";

const buttonStyle = {
  marginTop: 20,
};

export function OfferInfoForm(props) {
  const { action = "Save" } = props;

  const submitForm = (e) => {
    // const type = serviceTypes.find((i) => i.name === program.programType);
    // const payload = Object.assign({}, program, {programType: type});
    props.submit(offer);
  };
  const updateForm = (e) => {
    const key = e.target.id;
    let val = e.target.value;
    if (key === "prePayDiscount") {
      val = val || 0;
      val = Number.parseFloat(val);
    }
    const payload = {};
    payload[key] = val;
    setOffer({ ...offer, ...payload });
  };

  const localoffer =
    typeof props.offer === "undefined"
      ? {
          name: "",
          startDate: new Date(),
          endDate: new Date(),
          programs: [],
          active: true,
        }
      : props.offer;

  const [offer, setOffer] = useState(localoffer);

  const record = {
    programId: props.programs.length ? props.programs[0].id : "",
    discountType: "fixed-price", // fixedAmount, percentage, pricetable
    discountAmount: 0.0,
    discountPriceTableId: "",
    appliedTo: "first-app", // 'first-app, entire-program
    hide: false,
  };

  const createNew = (program) => {
    const newoffer = { ...offer };
    newoffer.programs.push(program);
    setOffer(newoffer);
    return false;
  };

  const removeProgram = (id) => {
    const newoffer = {
      ...offer,
      programs: offer.programs.filter((i) => i.programId !== id),
    };
    setOffer(newoffer);
  };

  return (
    <React.Fragment>
      <ValidatorForm onSubmit={submitForm}>
        <TextValidator
          id="name"
          label="Offer Name"
          value={offer.name}
          style={{ margin: 8, width: 300 }}
          placeholder="2020 Let's Get Mowing"
          margin="normal"
          onChange={updateForm}
          onBlur={updateForm}
          errorMessages={["this field is required"]}
          InputLabelProps={{
            shrink: true,
          }}
          validators={["required"]}
        />

        <div>
          <TextValidator
            id="startDate"
            label="Start Date"
            value={offer.startDate}
            style={{ margin: 8, width: 300 }}
            placeholder="2020 Let's Get Mowing"
            margin="normal"
            onChange={updateForm}
            onBlur={updateForm}
            type="date"
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true,
            }}
            validators={["required"]}
          />

          <TextValidator
            id="endDate"
            label="End Date"
            value={offer.endDate}
            style={{ margin: 8, width: 300 }}
            placeholder="2020 Let's Get Mowing"
            margin="normal"
            onChange={updateForm}
            onBlur={updateForm}
            type="date"
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true,
            }}
            validators={["required"]}
          />
        </div>
        <div>
          <FormLabel>Offer Active</FormLabel>
          <Checkbox
            id="active"
            label="Offer Active"
            checked={offer.active}
            onChange={(e) => {
              const payload = Object.assign({}, offer, {
                active: e.target.checked,
              });
              setOffer(payload);
            }}
          />
        </div>
        <br></br>
        <div>
          <FormLabel>Hide Offer Text</FormLabel>
          <Checkbox
            id="hideoffer"
            label="Hide Offer Text"
            checked={typeof offer.hide === "undefined" ? false : offer.hide}
            onChange={(e) => {
              const payload = Object.assign({}, offer, {
                hide: e.target.checked,
              });
              setOffer(payload);
            }}
          />
        </div>
        <br />
        <br />
        <Typography
          color="primary"
          style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}
        >
          Programs Applied
        </Typography>
        <hr />
        <Card
          style={{ padding: 20, backgroundColor: "#ccc", marginBottom: 40 }}
        >
          <OfferProgramForm {...props} createNew={createNew} record={record} />
        </Card>
        <OfferProgramTable
          {...props}
          offer={offer}
          removeProgram={removeProgram}
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={buttonStyle}
        >
          {action} Offer
        </Button>
      </ValidatorForm>
    </React.Fragment>
  );
}

const OfferProgramForm = (props) => {
  const [record, setRecord] = useState(props.record);

  const localSubmit = (e) => {
    props.createNew(record);
    setRecord({
      programId: props.programs.length ? props.programs[0].id : "",
      discountType: "fixed-price", // fixedAmount, percentage, pricetable
      discountAmount: 0.0,
      discountPriceTableId: "",
      appliedTo: "first-app", // 'first-app, entire-program,
      hide: false,
    });
  };

  return (
    <div>
      <Select
        labelid="program"
        id="program"
        placeholder="Select Program"
        label="Select Program"
        style={{
          marginTop: 24,
          marginRight: 10,
          marginLeft: 10,
          minWidth: 200,
        }}
        value={record.programId}
        onChange={(e) => {
          const payload = Object.assign({}, record, {
            programId: e.target.value,
          });
          setRecord(payload);
        }}
      >
        {props.programs.map((i) => {
          const key = "menu-item-" + Math.floor(Math.random() * 100000);
          return (
            <MenuItem key={i.id} value={i.id}>
              {i.programName}
            </MenuItem>
          );
        })}
      </Select>

      <Select
        labelid="discout"
        id="discout"
        placeholder="Select Discount Type"
        label="Select Discount Type"
        style={{
          marginTop: 24,
          marginRight: 10,
          marginLeft: 10,
          minWidth: 200,
        }}
        value={record.discountType}
        onChange={(e) => {
          const payload = Object.assign({}, record, {
            discountType: e.target.value,
          });
          setRecord(payload);
        }}
        validators={["required"]}
      >
        {[
          { id: "fixed-price", name: "Fixed Price" },
          { id: "percentage-off", name: "Percentage Off" },
          { id: "discounted-price-table", name: "Discounted Price Table" },
        ].map((i) => {
          const key = "menu-item-" + Math.floor(Math.random() * 100000);
          return (
            <MenuItem key={i.id} value={i.id}>
              {i.name}
            </MenuItem>
          );
        })}
      </Select>

      <Select
        labelid="applied"
        id="applied"
        label="Discount Applied To"
        style={{
          marginTop: 24,
          marginRight: 10,
          marginLeft: 10,
          minWidth: 200,
        }}
        value={record.appliedTo}
        onChange={(e) => {
          const payload = Object.assign({}, record, {
            appliedTo: e.target.value,
          });
          setRecord(payload);
        }}
        validators={["required"]}
      >
        {[
          { id: "first-app", name: "First Application" },
          { id: "entire-program", name: "Entire Program" },
        ].map((i) => {
          const key = "menu-item-" + Math.floor(Math.random() * 100000);
          return (
            <MenuItem key={i.id} value={i.id}>
              {i.name}
            </MenuItem>
          );
        })}
      </Select>

      {record.discountType == "discounted-price-table" ? (
        <Select
          labelid="priceTables"
          id="priceTables"
          placeholder="Select Price Table"
          label="Select Price Table"
          style={{
            marginTop: 24,
            marginRight: 10,
            marginLeft: 10,
            minWidth: 200,
          }}
          value={record.discountPriceTableId}
          onChange={(e) => {
            const payload = Object.assign({}, record, {
              discountPriceTableId: e.target.value,
            });
            setRecord(payload);
          }}
          validators={["required"]}
        >
          {props.priceTables.map((i) => {
            const key = "menu-item-" + Math.floor(Math.random() * 100000);
            return <MenuItem key={i.id} value={i.id}>{`${i.name}`}</MenuItem>;
          })}
        </Select>
      ) : null}

      {record.discountType !== "discounted-price-table" ? (
        <TextValidator
          id="amount"
          label="Amount"
          value={record.discountAmount}
          style={{ margin: 8, width: 50 }}
          placeholder="5%"
          margin="normal"
          onChange={(e) => {
            const payload = Object.assign({}, record, {
              discountAmount: e.target.value,
            });
            setRecord(payload);
          }}
          type="number"
          errorMessages={["this field is required"]}
          InputLabelProps={{
            shrink: true,
          }}
          validators={["required"]}
        />
      ) : null}
      <Button
        color="primary"
        variant="contained"
        onClick={localSubmit}
        style={buttonStyle}
      >
        Add Program
      </Button>
    </div>
  );
};

const OfferProgramTable = (props) => {
  const { offer, programs, priceTables } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Program</TableCell>
          <TableCell>Discount Type</TableCell>
          <TableCell>Discount Applied To</TableCell>
          <TableCell>Value</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {offer.programs.map((i) => {
          if (i === null || !programs.find((p) => p.id === i.programId))
            return null;
          const key = `programRow` + Math.floor(Math.random() * 1000000);
          return (
            <TableRow key={key}>
              <TableCell>
                {programs.find((p) => p.id === i.programId).programName}
              </TableCell>
              <TableCell>{i.discountType}</TableCell>
              <TableCell>{i.appliedTo}</TableCell>
              <TableCell>
                {i.discountType === "discounted-price-table"
                  ? priceTables.find((p) => p.id === i.discountPriceTableId)
                      .name
                  : i.discountAmount}
              </TableCell>
              <TableCell>
                <Button
                  color="primary"
                  id={i.programId}
                  variant="contained"
                  onClick={() => {
                    props.removeProgram(i.programId);
                  }}
                  style={buttonStyle}
                >
                  Remove
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

OfferInfoForm.propTypes = {
  offer: PropTypes.object,
  programs: PropTypes.array,
  priceTables: PropTypes.array,
  submit: PropTypes.func,
};
