import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Button,
  Snackbar,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";

import { Link as NavLink } from "react-router-dom";

import Title from "../../dashboard/Title";
import { API, graphqlOperation } from "aws-amplify";
import { BotInfoForm } from "./bot-info-form";
import { getBotVersioned } from "../../../graphql/queries";

import CodeBlock from "@tenon-io/tenon-codeblock";
import { generateCode } from "./init-code";

import { publishBot } from "./../../../utils/s3";
import { checkCompanyAfterFetch } from "../companyUtils";
import { createNewVersion } from "../../../utils/versionUtilFuncs";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export function CompanyBotDetail(props) {
  const history = useHistory();

  const { companyId, botId } = useParams();
  const classes = useStyles();

  const [bot, setBot] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [company, setCompany] = useState({});
  const [snackOpened, openSnack] = useState(false);

  const fetchCompany = useCallback(async () => {
    try {
      const fetchc = await checkCompanyAfterFetch(companyId);
      setCompany(fetchc.data.getCompany);

      const fetch = await API.graphql(
        graphqlOperation(getBotVersioned, {
          id: botId,
          version: "v0",
          limit: 1,
        }),
      );

      setBot(fetch.data.getBotVersioned);
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  }, [companyId, botId]);

  useEffect(() => {
    // fetch customer record here
    fetchCompany();
  }, [fetchCompany]);

  const updateBotCb = async (bot) => {
    const payload = { ...bot, botCompanyId: companyId };
    delete payload.__typename;

    if (!payload.zipCodes) {
      payload.zipCodes = "";
    }
    if (!payload.dontTaxZipCodes) {
      payload.dontTaxZipCodes = "";
    }
    try {
      console.log("attempting to save new bot", payload);
      await createNewVersion(bot.id, "bot", payload);
      // NOTE: This method is not proper, ideally we should handle the publish logic in the backend
      await fetchCompany()
      openSnack(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };

  const publishBotCb = () => {
    publishBot(company);
  };

  return loaded ? (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>
        <Link color="inherit" href={`/company/${companyId}/bots`}>
          Bots
        </Link>
        <Typography color="textPrimary">{bot.botTitle}</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Title>{bot.botTitle}</Title>
      </div>
      <div className={classes.container}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            postion: "relative",
            float: "right",
            marginRight: 20,
          }}
        >
          <Button
            style={{ marginBottom: 20, backgroundColor: "#3f51b5" }}
            color="secondary"
            variant="contained"
            href={`${process.env.REACT_APP_BOT_BASE_URL}/${companyId}/${botId}?preview=true&cookiesDisabled=true`}
            target={"_blank"}
          >
            Preview Bot Changes
          </Button>
          <Button onClick={publishBotCb} color="secondary" variant="contained">
            Generate and Publish
          </Button>
        </div>

        <br />
        <br />
        <BotInfoForm submit={updateBotCb} bot={bot} />
      </div>
      <div className={classes.container}>
        <Button
          color="primary"
          variant="contained"
          component={NavLink}
          to={`${bot.id}/learn-more`}
        >
          Learn More Questions
        </Button>
        <br />
        <br />
        <Button
          color="primary"
          variant="contained"
          component={NavLink}
          to={`${bot.id}/pre-modal`}
        >
          {bot.company.crmSolution === "servman" ? "Servman" : "Pre-Modal"}{" "}
          Questions
        </Button>
        <br />
        <br />
        {bot.company.crmSolution !== "servman" && (
          <Button
            color="primary"
            variant="contained"
            component={NavLink}
            to={`${bot.id}/objections`}
          >
            Objections
          </Button>
        )}
        <br />
        <br />
        {bot.company.crmSolution !== "servman" && (
          <Button
            color="primary"
            variant="contained"
            component={NavLink}
            to={`${bot.id}/service-questions`}
          >
            Service Questions
          </Button>
        )}

        <CodeBlock
          codeString={generateCode(
            companyId,
            bot.id,
            company.companyName,
            company.crmSolution,
          )}
          language="html"
        />
        <Snackbar
          open={snackOpened}
          autoHideDuration={1500}
          onClose={() => {
            openSnack(false);
            history.push(`/company/${companyId}/bot/${botId}`);
          }}
          message="Bot Saved!  Refreshing"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        />
      </div>
    </Container>
  ) : null;
}
