import PROBING_QUESTIONS_TEMPLATES from "../configs/probingQuestions/templates";
import { PROGRAM_TYPES } from "../constants";

const getProgramTypeTemplateQuestions = (type) => {
  switch (type) {
    case PROGRAM_TYPES.MOWING:
    case PROGRAM_TYPES.FERTILIZER_WEED_CONTROL:
    case PROGRAM_TYPES.LAWN_SEEDING:
      return PROBING_QUESTIONS_TEMPLATES.LAWN_CARE;
    case PROGRAM_TYPES.IRRIGATION_SERVICES:
      return PROBING_QUESTIONS_TEMPLATES.IRRIGATION_SERVICES;
    case PROGRAM_TYPES.PEST_CONTROL:
    case PROGRAM_TYPES.PERIMETER_PEST_CONTROL:
      return PROBING_QUESTIONS_TEMPLATES.PEST_CONTROL;
    case PROGRAM_TYPES.PET_WASTE:
      return PROBING_QUESTIONS_TEMPLATES.PET_WASTE;
    case PROGRAM_TYPES.COMMERCIAL_CLEANING:
      return PROBING_QUESTIONS_TEMPLATES.COMMERCIAL_CLEANING;
    case PROGRAM_TYPES.RESIDENTIAL_CLEANING:
      return PROBING_QUESTIONS_TEMPLATES.RESIDENTIAL_CLEANING;
    default:
      return [
        {
          id: "questions",
          steps: [],
          probing_questions: [],
        },
      ];
  }
};

export default getProgramTypeTemplateQuestions;
