export const templatedResidentialCleaning = [
  {
    name: "Residential Cleaning Probing Questions",
    type: "residential-cleaning",
    steps: [
      {
        id: "residential-cleaning-entry",
        message: "What is the total number of bedrooms in the home?",
        trigger: "get-residential-cleaning-bedroom-count",
      },
      {
        id: "residential-cleaning-bathroom-count",
        message: "What is the total number of bathrooms in the home?",
        trigger: "get-residential-cleaning-bathroom-count",
      },
      {
        id: "residential-cleaning-half-bathroom-count",
        message: "What is the total number of half bathrooms in the home?",
        trigger: "get-residential-cleaning-half-bathroom-count",
      },
      {
        id: "residential-cleaning-service-frequency",
        message: "How often would you like our service?",
        trigger: "get-residential-cleaning-service-frequency",
      },
    ],
    probing_questions: [
      {
        id: "get-residential-cleaning-bedroom-count",
        component: {
          type: "usernumberinput",
          subtype: "wholeNumber",
          min: 0,
          max: 99,
          placeholder: "Number of bedrooms (i.e. 3 )",
        },
        waitAction: true,
        setVariable: "residentialCleaningBedroomCount",
        trigger: "residential-cleaning-bathroom-count",
      },
      {
        id: "get-residential-cleaning-bathroom-count",
        component: {
          type: "usernumberinput",
          subtype: "wholeNumber",
          min: 0,
          max: 99,
          placeholder: "Number of bathrooms (i.e. 2 )",
        },
        waitAction: true,
        setVariable: "residentialCleaningBathroomCount",
        trigger: "residential-cleaning-half-bathroom-count",
      },
      {
        id: "get-residential-cleaning-half-bathroom-count",
        component: {
          type: "usernumberinput",
          subtype: "wholeNumber",
          min: 0,
          max: 99,
          placeholder: "Number of half bathrooms (i.e. 2 )",
        },
        waitAction: true,
        setVariable: "residentialCleaningHalfBathroomCount",
        trigger: "residential-cleaning-service-frequency",
      },
      {
        id: "get-residential-cleaning-service-frequency",

        component: {
          type: "userinput",
          placeholder:
            "Frequency of Service (i.e. Weekly, Bi-Weekly, Monthly, One Time )",
        },
        waitAction: true,
        setVariable: "residentialCleaningServiceFrequency",
        trigger: "next-service",
      },
    ],
  },
];
