module.exports.learnMoreTemplate = (company, lm) => {
  if (!company.branding) {
    company.branding = {};
  }

  return JSON.stringify(
    [
      {
        id: 1,
        name: "LearnMore",
        steps: [
          {
            id: "company-learn-more-description",
            message:
              company.branding.valueAddDescription || "Learn More about Us",
            trigger: "ready-question",
          },
        ],
        probing_questions: [
          {
            id: "learn-more",
            component: {
              type: "html",
              html: getValueAddHTML(company.branding),
            },
            asMessage: false,
            trigger: "company-learn-more-description",
          },
        ],
      },
    ],
    null,
    2,
  );
};

const getValueAddHTML = (branding) => {
  if (branding.aboutUsAssetUrl) {
    const url = branding.aboutUsAssetUrl.split("?")[0];
    if (branding.aboutUsAssetUrl.indexOf("mp4") !== -1) {
      return `<video width="100%" controls autoplay>
            <source src="${url}" type="video/mp4">
            </video>`;
    } else {
      return `<img src='${url}' width='100%' />`;
    }
  } else {
    return ``;
  }
};

module.exports.getValueAddHTML = getValueAddHTML;
