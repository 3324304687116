module.exports.objectionsTemplate = (company) => {
  const standardTemplate = [
    {
      id: "objections",
      name: "objections",
      steps: [
        {
          id: "company-objection-price-entry",
          message:
            "I understand! We may not be the cheapest but with " +
            company.companyName +
            " you get a family-owned and operated company, that will give you the personal attention a beautiful lawn requires.",
          trigger: "company-objection-price-tiedown-ask",
        },
        {
          id: "company-objection-price-m2",
          message: "API price objection message 2",
          trigger: "company-objection-price-tiedown-ask",
        },
        {
          id: "company-objection-price-tiedown-ask",
          message:
            "At " +
            company.companyName +
            ", high-quality services and our customers’ satisfaction are also the core of our business. We genuinely care about you and our community.",
          trigger: "company-objection-price-tiedown-get",
        },

        ///// start service objections
        {
          id: "company-objection-service-entry",
          message:
            "We understand. We place service as a top priority here at " +
            company.companyName +
            ".",
          trigger: "company-objection-service-tiedown-ask",
        },
        {
          id: "company-objection-service-m2",
          message:
            "We use all liquid Green County fertilizer products, unlike some of the national lawn care companies who use cheap granular fertilizers. We make the soil healthier with each and every visit.",
          trigger: "company-objection-service-m3",
        },
        {
          id: "company-objection-service-m3",
          message:
            "Liquid applications of broadleaf weed control are included with all lawn care programs. There are currently NO effective post-emergent organic weed control products available, so each of our programs includes conventional weed control. We are happy to adjust any program to meet your goals.",
          trigger: "company-objection-service-m4",
        },
        {
          id: "company-objection-service-m4",
          message:
            "With that in mind, I would love to get on your lawn this season.",
          trigger: "company-objection-service-tiedown-ask",
        },
        {
          id: "company-objection-service-tiedown-ask",
          message: "Would you like to sign up 😀?",
          trigger: "company-objection-service-tiedown-get",
        },

        /// stop service objections

        {
          id: "company-objection-people-entry",
          message:
            "We are a local family-owned business right here in your area. With " +
            company.companyName +
            " you get the hands-on personal touch that gets lost when dealing with large national companies!",
          trigger: "company-objection-people-tiedown-ask",
        },
        {
          id: "company-objection-people-m2",
          message: "show 4 star reviews",
        },
        {
          id: "company-objection-people-tiedown-ask",
          message: "I'm sure you can see how that would work for you, right?",
          trigger: "company-objection-people-tiedown-get",
        },
      ],
      probing_questions: [
        {
          id: "get-objection-reason",
          options: [
            {
              value: "Price",
              label: "Price",
              iconClass: ["fas", "dollar-sign"],
              color: "yellow",
            },
            {
              value: "Service",
              label: "Service",
              iconClass: ["fas", "cog"],
              color: "yellow",
            },
            {
              value: "People",
              label: "People",
              iconClass: ["fas", "user-alt"],
              color: "yellow",
            },
          ],
          optionType: "icon-text-option",
          fillOptions: true,
          setVariable: "objectionReason",
          conditionals: [
            {
              matches: "Price",
              trigger: "company-objection-price-entry",
            },
            {
              matches: "Service",
              trigger: "company-objection-service-entry",
            },
            {
              matches: "People",
              trigger: "company-objection-people-entry",
            },
          ],
        },
        {
          id: "company-objection-price-tiedown-get",
          options: [
            {
              value: "Let's do it",
              label: "Let's do it",
              hideText: true,
            },
            {
              value: "I still need to think about it",
              label: "I still need to think about it",
              hideText: true,
            },
          ],
          optionType: "default",
          setVariable: "objectionPriceTiedown",
          conditionals: [
            {
              matches: "Let's do it",
              trigger: "rebutal-positive",
            },
            {
              matches: "I still need to think about it",
              trigger: "well-thanks-anyway",
            },
          ],
        },
        {
          id: "company-objection-service-tiedown-get",
          options: [
            {
              value: "Let's do it",
              label: "Let's do it",
              hideText: true,
            },
            {
              value: "I still need to think about it",
              label: "I still need to think about it",
              hideText: true,
            },
          ],
          optionType: "default",
          setVariable: "objectionProductTiedown",
          conditionals: [
            {
              matches: "Let's do it",
              trigger: "rebutal-positive",
            },
            {
              matches: "I still need to think about it",
              trigger: "well-thanks-anyway",
            },
          ],
        },
        {
          id: "company-objection-people-tiedown-get",
          options: [
            {
              value: "Let's do it",
              label: "Let's do it",
              hideText: true,
            },
            {
              value: "I still need to think about it",
              label: "I still need to think about it",
              hideText: true,
            },
          ],
          optionType: "default",
          setVariable: "objectionPeopleTiedown",
          conditionals: [
            {
              matches: "Let's do it",
              trigger: "rebutal-positive",
            },
            {
              matches: "I still need to think about it",
              trigger: "well-thanks-anyway",
            },
          ],
        },
      ],
    },
  ];

  return JSON.stringify(standardTemplate);

  // return JSON.stringify([
  //   {
  //     id: "objections",
  //     name: "objections",
  //     steps: [
  //       {
  //         id: "company-objection-price-entry",
  //         message: `I understand! We may not be the cheapest but with ${company.companyName} you get a family-owned and operated company, that will give you the personal attention a beautiful lawn requires.`,
  //         trigger: "company-objection-price-tiedown-ask"
  //       },
  //       {
  //         id: "company-objection-price-m2",
  //         message: "API price objection message 2",
  //         trigger: "company-objection-price-tiedown-ask"
  //       },
  //       {
  //         id: "company-objection-price-tiedown-ask",
  //         message:
  //           "You can see how the hands on personal touch makes all the difference right?",
  //         trigger: "company-objection-price-tiedown-get"
  //       },

  //       {
  //         id: "company-objection-service-entry",
  //         message: `${company.companyName} uses the newest technology to decrease the use of pesticides while improving the overall look and health of your lawn. We strongly believe the biggest obstacle in our industry is having uneducated customers. That’s why our goal is to build a company that revolutionizes the green industry by bridging the gap between customers and company.`,
  //         trigger: "company-objection-service-tiedown-ask"
  //       },
  //       {
  //         id: "company-objection-service-m2",
  //         message:
  //           "We use all liquid Green County fertilizer products, unlike some of the national lawn care companies who use cheap granular fertilizers. We make the soil healthier with each and every visit.",
  //         trigger: "company-objection-service-m3"
  //       },
  //       {
  //         id: "company-objection-service-m3",
  //         message:
  //           "Liquid applications of broadleaf weed control are included with all lawn care programs. There are currently NO effective post-emergent organic weed control products available, so each of our programs includes conventional weed control. We are happy to adjust any program to meet your goals.",
  //         trigger: "company-objection-service-m4"
  //       },
  //       {
  //         id: "company-objection-service-m4",
  //         message:
  //           "With that in mind, I would love to get on your lawn this season.",
  //         trigger: "company-objection-service-tiedown-ask"
  //       },
  //       {
  //         id: "company-objection-service-tiedown-ask",
  //         message: "Would you like to sign up 😀?",
  //         trigger: "company-objection-service-tiedown-get"
  //       },

  //       {
  //         id: "company-objection-people-entry",
  //         message: `We a local family-owned business right here in your area. With ${company.companyName} you get the hands-on personal touch that gets lost when dealing with large national companies!`,
  //         trigger: "company-objection-people-tiedown-ask"
  //       },
  //       { id: "company-objection-people-m2", message: "show 4 star reviews" },
  //       {
  //         id: "company-objection-people-tiedown-ask",
  //         message: "I'm sure you can see how that would work for you, right?",
  //         trigger: "company-objection-people-tiedown-get"
  //       }
  //     ],
  //     probing_questions: [
  //       {
  //         id: "get-objection-reason",
  //         options: [
  //           {
  //             value: "Price",
  //             label: "Price",
  //             iconClass: ["fas", "dollar-sign"],
  //             color: "yellow"
  //           },
  //           {
  //             value: "Product",
  //             label: "Product",
  //             iconClass: ["fas", "cog"],
  //             color: "yellow"
  //           },
  //           {
  //             value: "People",
  //             label: "People",
  //             iconClass: ["fas", "user-alt"],
  //             color: "yellow"
  //           }
  //         ],
  //         optionType: "icon-text-option",
  //         fillOptions: true,
  //         setVariable: "objectionReason",
  //         conditionals: [
  //           { matches: "Price", trigger: "company-objection-price-entry" },
  //           { matches: "Product", trigger: "company-objection-service-entry" },
  //           { matches: "People", trigger: "company-objection-people-entry" }
  //         ]
  //       },
  //       {
  //         id: "company-objection-price-tiedown-get",
  //         options: [
  //           { value: "Let's do it", label: "Let's do it", hideText: true },
  //           {
  //             value: "I still need to think about it",
  //             label: "I still need to think about it",
  //             hideText: true
  //           }
  //         ],
  //         optionType: "default",
  //         setVariable: "objectionPriceTiedown",
  //         conditionals: [
  //           { matches: "Let's do it", trigger: "rebutal-positive" },
  //           {
  //             matches: "I still need to think about it",
  //             trigger: "well-thanks-anyway"
  //           }
  //         ]
  //       },
  //       {
  //         id: "company-objection-service-tiedown-get",
  //         options: [
  //           { value: "Let's do it", label: "Let's do it", hideText: true },
  //           {
  //             value: "I still need to think about it",
  //             label: "I still need to think about it",
  //             hideText: true
  //           }
  //         ],
  //         optionType: "default",
  //         setVariable: "objectionProductTiedown",
  //         conditionals: [
  //           { matches: "Let's do it", trigger: "rebutal-positive" },
  //           {
  //             matches: "I still need to think about it",
  //             trigger: "well-thanks-anyway"
  //           }
  //         ]
  //       },
  //       {
  //         id: "company-objection-people-tiedown-get",
  //         options: [
  //           { value: "Let's do it", label: "Let's do it", hideText: true },
  //           {
  //             value: "I still need to think about it",
  //             label: "I still need to think about it",
  //             hideText: true
  //           }
  //         ],
  //         optionType: "default",
  //         setVariable: "objectionPeopleTiedown",
  //         conditionals: [
  //           { matches: "Let's do it", trigger: "rebutal-positive" },
  //           {
  //             matches: "I still need to think about it",
  //             trigger: "well-thanks-anyway"
  //           }
  //         ]
  //       }
  //     ]
  //   }
  // ]);
};
