export const templatedIrrigation = [
  {
    name: "Irrigation Services Probing Questions",
    type: "irrigation-services",
    steps: [
      {
        id: "irrigation-entry",
        message: `Is this a new or existing irrigation system?`,
        trigger: "get-irrigation-system-type",
      },
      {
        id: "ask-soil-condition",
        message: `What is the current soil condition?`,
        trigger: "get-soil-condition",
      },
      {
        id: "ask-landscape-conditions",
        message: `What are the lawn/landscape conditions? `,
        trigger: "get-landscape-conditions",
      },
      {
        id: "ask-kind-of-home",
        message: `What kind of home do you have?`,
        trigger: "get-kind-of-home",
      },
      {
        id: "ask-how-many-zones",
        message: `How many zones do you have?`,
        trigger: "get-how-many-zones",
      },
    ],
    probing_questions: [
      {
        id: "get-irrigation-system-type",
        options: [
          { value: "New", label: "New", hideText: true },
          { value: "Existing", label: "Existing", hideText: true },
        ],
        optionType: "default",
        setVariable: "irrigationSystemType",
        fillOptions: true,
        conditionals: [
          { matches: "New", trigger: "ask-soil-condition" },
          { matches: "Existing", trigger: "ask-how-many-zones" },
        ],
      },

      {
        id: "get-soil-condition",
        options: [
          {
            value: "Sandy Soil",
            label: "Sandy Soil",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/sandy_soil.jpg",
          },
          {
            value: "Clay Soil",
            label: "Clay Soil",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/clay_soil.jpg",
          },
          {
            value: "Rocky Soil",
            label: "Rocky Soil",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/rocky_soil.jpg",
          },
          {
            value: "Rooty Soil",
            label: "Rooty Soil",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/rooty_soil.jpeg",
          },
        ],
        setVariable: "soilType",
        optionType: "image-text-option",
        fillOptions: true,
        trigger: "ask-landscape-conditions",
      },

      {
        id: "get-landscape-conditions",
        options: [
          { value: "Normal", label: "Normal", hideText: true },
          { value: "Meticulous", label: "Meticulous", hideText: true },
        ],
        optionType: "default",
        trigger: "ask-kind-of-home",
        fillOptions: true,
        setVariable: "landscapeConditions",
      },
      {
        id: "get-kind-of-home",
        options: [
          { value: "New Home", label: "New Home", hideText: true },
          {
            value: "Established Home",
            label: "Established Home",
            hideText: true,
          },
          {
            value: "Under Construction",
            label: "Under Construction",
            hideText: true,
          },
        ],
        optionType: "default",
        setVariable: "homeType",
        trigger: "new-home-send-note",
      },

      {
        id: "get-how-many-zones",
        component: {
          type: "userinput",
          placeholder: "Number of Zones (i.e. 10 )",
        },
        waitAction: true,
        setVariable: "numberOfZones",
        trigger: "next-service",
      },

      {
        id: "new-home-send-note",
        message:
          "Fantastic!  This information is extremely useful for use to give you the best quote possible.",
        trigger: "well-thanks-anyway",
      },
    ],
  },
];
