import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Breadcrumbs,
  Link,
  Typography,
  Snackbar,
  FormLabel,
  Checkbox,
  Select,
} from "@material-ui/core";

import Title from "../../dashboard/Title";
import { API, graphqlOperation } from "aws-amplify";
import { getCompany } from "../../../graphql/queries";
import { updateCompany } from "../../../graphql/mutations";
import {
  getCompanyEmployees,
  getCompanyMarketingCodes,
  getCompanyCallActionReasons,
  getCompanyCallStatuses,
  getCompanies,
  getCompanyRoutes,
} from "../../../integrations/realgreen/api_calls";

import { TextField, MenuItem, Button } from "@material-ui/core";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { checkCompanyAfterFetch } from "../companyUtils";
import { createNewVersion } from "../../../utils/versionUtilFuncs";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

const statuses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
  return { value: `${i}`, label: `${i}` };
});

export function RealGreenIntegration(props) {
  const { companyId } = useParams();
  const classes = useStyles();

  const [company, setCompany] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [realGreenInfo, setRealGreenInfo] = useState({});
  const [dataServices, setDataServices] = useState({});
  const [employees, setEmployees] = useState([]);
  const [marketingCodes, setMarketingCodes] = useState([]);
  const [callReasons, setCallReasons] = useState([]);
  const [callStatuses, setCallStatuses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [routes, setRoutes] = useState([]);

  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Settings Saved");

  const crmUnitOptions = [
    {
      label: "1 Sq foot",
      value: 1,
    },
    {
      label: "1000 Sq feet",
      value: 1000,
    },
    {
      label: "1 Acre",
      value: 43560,
    },
  ];

  const fetchCompany = useCallback(async () => {
    try {
      const fetch = await checkCompanyAfterFetch(companyId);

      fetch.data.getCompany.crmCompanyId =
        typeof fetch.data.getCompany.crmCompanyId === "undefined"
          ? "-1"
          : fetch.data.getCompany.crmCompanyId + "";
      setCompany(fetch.data.getCompany);
      const ds = fetch.data.getCompany.dataServices || {
        stripeTestSecret: "",
        stripeSecret: "",
        realGreenAPIKey: "",
        serviceAssistantId: "",
        zillowAPIKey: "",
        airTableTableName: "",
        airTableBase: "",
      };
      setDataServices(ds);
      setRealGreenInfo({
        lawnbotEmployee: fetch.data.getCompany.lawnbotEmployee || null,
        marketingSource: fetch.data.getCompany.marketingSource || null,
        callStatusCode: fetch.data.getCompany.callStatusCode || null,
        callActionReason: fetch.data.getCompany.callActionReason || null,
        crmNewRecordStatusScode:
          fetch.data.getCompany.crmNewRecordStatusScode || "0",
        crmQuoteStatusCode: fetch.data.getCompany.crmQuoteStatusCode || "1",
        crmSaleStatusCode: fetch.data.getCompany.crmSaleStatusCode || "9",
        crmCompanyId:
          typeof fetch.data.getCompany.crmCompanyId === "undefined"
            ? "-1"
            : fetch.data.getCompany.crmCompanyId + "",
        crmRoute: fetch.data.getCompany.crmRoute || null,
        crmDayCode: fetch.data.getCompany.crmDayCode || null,
        crmHandheld:
          typeof fetch.data.getCompany.crmHandheld === "undefined"
            ? false
            : fetch.data.getCompany.crmHandheld,
      });

      const rgKey = ds.realGreenAPIKey || null;
      if (rgKey) {
        Promise.all([
          getCompanyEmployees(rgKey),
          getCompanyMarketingCodes(rgKey),
          getCompanyCallActionReasons(rgKey),
          getCompanyCallStatuses(rgKey),
          getCompanies(rgKey),
          getCompanyRoutes(rgKey),
        ]).then(
          ([
            lemployees,
            marketingcodes,
            callreasons,
            callstatuses,
            lcompanies,
            lroutes,
          ]) => {
            setEmployees(lemployees);
            setMarketingCodes(marketingcodes);
            setCallReasons(callreasons);
            setCallStatuses(callstatuses);
            setCompanies(lcompanies);
            setRoutes(lroutes);
            setLoaded(true);
          },
        );
      }
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  }, [companyId]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  const saveRealGreenCompanyData = async () => {
    const payload = { ...company };
    try {
      await createNewVersion(companyId, "company", payload);
      openSnack(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };

  return loaded ? (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>

        <Typography color="textPrimary">RealGreen</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <ValidatorForm onSubmit={() => {}}>
          <Title>RealGreen Settings</Title>

          <div>
            <SelectValidator
              id="companyId"
              label="Company Id"
              value={company.crmCompanyId || null}
              style={{ margin: 8, width: 220 }}
              placeholder="Select Company"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, crmCompanyId: e.target.value });
              }}
            >
              <MenuItem key={"defaultcompid"} value={-1}>
                No Company Selected
              </MenuItem>
              {companies.map((i) => {
                console.log(i);
                const key = "menu-item-" + Math.floor(Math.random() * 100000);
                return (
                  <MenuItem key={i.comp_id + "adfaddw"} value={i.comp_id}>
                    {i.compname + " ( " + i.comp_id + " )"}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <SelectValidator
              id="employee"
              label="Lawnbot Employee"
              value={company.lawnbotEmployee || null}
              style={{ margin: 8, width: 220 }}
              placeholder="Lawnbot"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, lawnbotEmployee: e.target.value });
              }}
            >
              {employees.map((i) => {
                const key = "menu-item-" + Math.floor(Math.random() * 100000);
                return (
                  <MenuItem
                    key={i.emp_id.trim() + "adfaddw"}
                    value={i.emp_id.trim()}
                  >
                    {i.name}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <SelectValidator
              id="route"
              label="Route"
              value={company.crmRoute || null}
              style={{ margin: 8, width: 220 }}
              placeholder="Lawnbot"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, crmRoute: e.target.value });
              }}
            >
              {routes.map((i) => {
                const key = "menu-item-" + Math.floor(Math.random() * 100000);
                return (
                  <MenuItem
                    key={i.emp_id.trim() + "adfaddw"}
                    value={i.route.trim()}
                  >
                    {i.route_desc}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <SelectValidator
              id="marketing-source"
              label="Marketing Source"
              value={company.marketingSource || null}
              style={{ margin: 8, width: 220 }}
              placeholder="Chatbot"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, marketingSource: e.target.value });
              }}
            >
              {marketingCodes.map((i) => {
                const key = "menu-item-" + Math.floor(Math.random() * 100000);
                return (
                  <MenuItem key={i.sourcecd + "123123"} value={i.sourcecd + ""}>
                    {i.source}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <SelectValidator
            id="call-status"
            label="Sale Call Status"
            value={company.callStatusCode || null}
            style={{ margin: 8, width: 220 }}
            placeholder="Sales Call"
            margin="normal"
            onChange={(e) => {
              setCompany({ ...company, callStatusCode: e.target.value });
            }}
          >
            {callStatuses.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={i.status + "-dasd"} value={i.status + ""}>
                  {i.statusdesc}
                </MenuItem>
              );
            })}
          </SelectValidator>

          <div>
            <SelectValidator
              id="call-action-reason"
              label="Sale Call Action Reason"
              value={company.callActionReason || null}
              style={{ margin: 8, width: 220 }}
              placeholder="Sales Call"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, callActionReason: e.target.value });
              }}
            >
              {callReasons.map((i) => {
                const key = "menu-item-" + Math.floor(Math.random() * 100000);
                return (
                  <MenuItem
                    key={i.actreasid + "-adas"}
                    value={i.actreasid + ""}
                  >
                    {i.actreas}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>
          <div>
            <TextValidator
              id="crmPortalUrl"
              label="RealGreen Customer Portal"
              value={company.crmPortalUrl || ""}
              style={{ margin: 8, width: 400 }}
              placeholder="https://"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              errorMessages={["this field is required"]}
              validators={[]}
              onChange={(e) => {
                setCompany({ ...company, crmPortalUrl: e.target.value });
              }}
              onBlur={(e) => {
                setCompany({ ...company, crmPortalUrl: e.target.value });
              }}
            />
          </div>

          <div>
            <SelectValidator
              id="newRecordStatus"
              label="New Record Status"
              value={company.crmNewRecordStatusScode || "0"}
              style={{ margin: 8, width: 220 }}
              placeholder="0"
              margin="normal"
              onChange={(e) => {
                setCompany({
                  ...company,
                  crmNewRecordStatusScode: e.target.value,
                });
              }}
            >
              {statuses.map((i) => {
                const key = "menu-item-" + Math.floor(Math.random() * 100000);
                return (
                  <MenuItem key={key} value={i.value}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <SelectValidator
              id="crmQuoteStatusCode"
              label="Quote Received Status"
              value={company.crmQuoteStatusCode || "1"}
              style={{ margin: 8, width: 220 }}
              placeholder="0"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, crmQuoteStatusCode: e.target.value });
              }}
            >
              {statuses.map((i) => {
                const key = "menu-item-" + Math.floor(Math.random() * 100000);
                return (
                  <MenuItem key={key} value={i.value}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <SelectValidator
              id="crmSaleStatusCode"
              label="Sale Made Status"
              value={company.crmSaleStatusCode || "9"}
              style={{ margin: 8, width: 220 }}
              placeholder="0"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, crmSaleStatusCode: e.target.value });
              }}
            >
              {statuses.map((i) => {
                const key = "menu-item-" + Math.floor(Math.random() * 100000);
                return (
                  <MenuItem key={key} value={i.value}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <TextValidator
              id="crmRoute"
              label="RealGreen Route Label"
              value={
                typeof company.crmRoute !== "undefined"
                  ? company.crmRoute
                  : "null"
              }
              style={{ margin: 8, width: 400 }}
              placeholder="XXX - Example Route"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              errorMessages={["this field is required"]}
              validators={[]}
              onChange={(e) => {
                setCompany({ ...company, crmRoute: e.target.value });
              }}
              onBlur={(e) => {
                setCompany({ ...company, crmRoute: e.target.value });
              }}
            />
          </div>

          <div>
            <TextValidator
              id="crmDayCode"
              label="RealGreen Day Code"
              value={
                typeof company.crmDayCode !== "undefined"
                  ? company.crmDayCode
                  : ""
              }
              style={{ margin: 8, width: 400 }}
              placeholder="1"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              errorMessages={["this field is required"]}
              validators={[]}
              onChange={(e) => {
                setCompany({ ...company, crmDayCode: e.target.value });
              }}
              onBlur={(e) => {
                setCompany({ ...company, crmDayCode: e.target.value });
              }}
            />
          </div>
          <div>
            <SelectValidator
              id="crm-measurement-unit"
              label="CRM Measurement Unit"
              value={company.crmMeasurementUnit || null}
              style={{ margin: 8, width: 220 }}
              placeholder="CRM Measurement Unit"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, crmMeasurementUnit: e.target.value });
              }}
            >
              {crmUnitOptions.map((i) => {
                const key = "menu-item-" + i.value;
                return (
                  <MenuItem key={key} value={i.value}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>
          <div style={{ padding: 15 }}>
            <FormLabel>Make Available On Mobile</FormLabel>
            <Checkbox
              id="crmHandheld"
              label="ByPass Credit Card Payment"
              checked={company.crmHandheld}
              onChange={(e) => {
                const payload = Object.assign({}, company, {
                  crmHandheld: e.target.checked,
                });
                setCompany(payload);
              }}
            />
          </div>

          <Button
            color="primary"
            variant="contained"
            onClick={saveRealGreenCompanyData}
          >
            Save
          </Button>
        </ValidatorForm>
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={2000}
        onClose={() => {
          openSnack(false);
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      ></Snackbar>
    </Container>
  ) : null;
}
