export const generateCode = (
  companyId,
  botId,
  companyName = "LawnCare Bot",
  crmSolution = "real-green",
) => {
  const botUrl = "https://sales.lawnbot.biz";
  return `<html>
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
        <title>${companyName} Lawn Care</title>
        <style>
            .LawnBotFullPage, .LawnBotFullPage-container {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1000;
            }
        </style>
    </head>
    <body onload="">
        <script>
            const botConfig = {
                customerId: '${companyId}',
                botId: '${botId}'
            }
        </script>
        <script>
           function loadBot() {
               const search = window.location.search;
                const body = document.getElementsByTagName('body')[0];
                body.innerHTML = \`
                <div class="LawnBotFullPage">
                    <div class="LawnBotFullPage-container">
                        <iframe frameborder="0" width="100%" height="100%"
                        src="${botUrl}/\${botConfig.customerId}/\${botConfig.botId}/\${search}"
                        sandbox="allow-popups allow-top-navigation allow-scripts allow-same-origin allow-modals" />
                    </div>
                </div>
                \`;
            }
            setTimeout(() => {
                loadBot();
            }, 500);
           
        </script>
    </body>
</html>`;
};
