import React, { useState } from "react";
import { Storage } from "aws-amplify";
import { Container, Button } from "aws-amplify-react";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

export default function S3ImageUploader(props) {
  const { companyId } = useParams();
  const {
    filename,
    image,
    uploaded,
    type,
    allowableContentType,
    startLoading,
  } = props;

  let timage = image || null;
  let isVideo = false;
  if (timage) {
    timage = timage.split("?")[0];
    if (timage.indexOf(".mp4") !== -1) {
      isVideo = true;
    }
  }

  const [targetImage, setTargetImage] = useState(timage);
  const [tisVideo, setTisVideo] = useState(isVideo);
  const onChange = (e) => {
    startLoading();
    const file = e.target.files[0];

    const name = file.name;
    const lastDot = name.lastIndexOf(".");
    const ext = name.substring(lastDot + 1);
    let contentType = `image/${ext}`;

    if (file.name.indexOf(".mp4") !== -1) {
      contentType = "video/mp4";
    }

    Storage.put(`${companyId}/${filename}.${ext}`, file, {
      contentType: contentType,
    })
      .then((result) => {
        Storage.get(result.key).then((path) => {
          setTargetImage(path);
          if (ext === "mp4") {
            setTisVideo(true);
          } else {
            setTisVideo(false);
          }
          uploaded(type, path, contentType);
        });
      })
      .catch((err) => console.log(err));
  };

  const removeImage = (contentType) => {
    setTargetImage(null);
    uploaded(type, null, contentType, true);
  };

  const displayElement = () => {
    if (!tisVideo) {
      return (
        <DisplayImage
          {...props}
          contentType="image/png"
          removeImage={removeImage}
          filename={targetImage}
          label={"Image"}
        />
      );
    } else {
      return (
        <DisplayVideo
          {...props}
          contentType="video/mp4"
          removeImage={removeImage}
          filename={targetImage}
          label={"Video"}
        />
      );
    }
  };

  return (
    <Container>
      {targetImage ? (
        displayElement()
      ) : (
        <div>
          <input
            type="file"
            accept={allowableContentType}
            onChange={(e) => onChange(e)}
          />
        </div>
      )}
    </Container>
  );
}

S3ImageUploader.propTypes = {
  uploaded: PropTypes.func,
  type: PropTypes.string,
};

function DisplayVideo(props) {
  const { filename, removeImage, label, contentType } = props;

  return (
    <div>
      <video width="500" controls>
        <source src={filename} type="video/mp4"></source>
      </video>
      <Button
        style={{ backgroundColor: "red" }}
        onClick={() => removeImage(contentType)}
        color="red"
        variant="contained"
      >
        Remove {label}
      </Button>
    </div>
  );
}

function DisplayImage(props) {
  const { filename, removeImage, label, contentType } = props;

  return (
    <div>
      <img src={filename} alt="target" style={{ width: "80%" }} />
      <Button
        style={{ backgroundColor: "red" }}
        onClick={() => removeImage(contentType)}
        color="red"
        variant="contained"
      >
        Remove {label}
      </Button>
    </div>
  );
}
