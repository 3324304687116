import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Title from "../dashboard/Title";

import { API, graphqlOperation } from "aws-amplify";
import { listCompanyVersioneds } from "../../graphql/queries";
import CompanyListTable from "./company-list-table";
import { Button } from "@material-ui/core";

import * as _ from "lodash";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  newButton: {
    position: "relative",
    float: "right",
    marginRight: 20,
  },
}));

export default function CompanyPage(props) {
  const classes = useStyles();

  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchCompaniesPage = (page) => {
      return new Promise((res, rej) => {
        let list = [];
        try {
          API.graphql(
            graphqlOperation(listCompanyVersioneds, {
              filter: { version: { eq: "v0" } },
              nextToken: page,
            }),
          ).then(async (c) => {
            const stuff =
              c.data.listCompanyVersioneds && c.data.listCompanyVersioneds.items
                ? _.sortBy(c.data.listCompanyVersioneds.items, ["companyName"])
                : [];
            if (
              c.data.listCompanyVersioneds &&
              c.data.listCompanyVersioneds.items &&
              c.data.listCompanyVersioneds.nextToken
            ) {
              const d = await fetchCompaniesPage(
                c.data.listCompanyVersioneds.nextToken,
              );
              list = [...list, ...d];
            }
            res(list);
          });
        } catch (e) {
          console.log(e);
          res(list);
        }
      });
    };
    const fetchCompanies = async () => {
      try {
        const fetch = await API.graphql(
          graphqlOperation(listCompanyVersioneds, {
            filter: { version: { eq: "v0" } },
            limit: 10000,
          }),
        );
        let c =
          fetch.data.listCompanyVersioneds &&
          fetch.data.listCompanyVersioneds.items
            ? _.sortBy(fetch.data.listCompanyVersioneds.items, ["companyName"])
            : [];
        console.log(fetch.data.listCompanyVersioneds.items.length);
        if (fetch.data.listCompanyVersioneds.nextToken) {
          const more = await fetchCompaniesPage(
            fetch.data.listCompanyVersioneds.nextToken,
          );
          c = [...c, ...more];
        }
        setCompanies(c);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    fetchCompanies();
  }, []);

  return (
    <div classes={classes.root}>
      <Title>Companies</Title>
      <Button
        className={classes.newButton}
        color="primary"
        variant="contained"
        component={Link}
        to="/company/new"
      >
        New +
      </Button>
      <CompanyListTable companies={companies} classes />
    </div>
  );
}
